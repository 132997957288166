@import "../../assets/style/sass/lynk-colors";
@import "../../assets/fonts/manrope/manrope.css";

.checkboxText {
  padding-left: 35px;
  margin-bottom: 12px;
  font-family: 'manrope';
  font-size: 15px;
  line-height: 22px;
  color: #4A5158;
}

.checkboxText a {
  color: $background_other2;
  text-decoration: underline;
}

.checkboxText a:hover {
  color: neutral_900
}

.checkboxContainer {
  float: left;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkboxContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: -1px;
  left: 0;
  height: 25px;
  width: 25px;
  border: 1px solid $tertiary_4;
  background-color: $grey_1;
  border-radius: 3px;
}

/* When the checkbox is checked, add a blue background */
.checkboxContainer input:checked ~ .checkmark {
  background-color: $neutral_900;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkboxContainer input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkboxContainer .checkmark:after {
  top: 4px;
  left: 8px;
  width: 7px;
  height: 12px;
  border: solid $white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.checkboxComp {
  float: left;
}

@media (max-width: 768px) {
  .checkboxText {
    line-height: 25px;
    margin-top: -2px;
  }
  .checkmark {
    height: 20px;
    width: 20px;
    left: 8px;
    top: 1px;
  }
  .checkboxContainer .checkmark:after {
    top: 2px;
    left: 5px;
    width: 7px;
    height: 12px;
  }
}