@import "../../assets/style/sass/lynk-colors";

.header {
  border-bottom: 1px solid $tertiary_3;
  padding: 20px;
  text-align: center;;
}

.container {
  padding: 60px 30px;
  max-width: 800px;
  margin: 0px auto;
}

.container h4 {
  font-weight: normal;
  line-height: 40px;
  font-size: 34px;
  text-align: center;
  color: $grey_4;
  margin: 0px 0px 60px 0px;
}

.container a {
  color: $neutral_800;
}

.container a:hover {
  color: #1c8481;
}

.logo {
  max-width: 90px;
}

.strong {
  font-weight: bold;
  margin-top: 25px;
}

.container p, .container li {
  font-style: normal;
  font-weight: normal;
  list-style-type: disc;
  line-height: 22px;
  font-size: 15px;
}

.textCenter {
  text-align: center;
}

.suggestions {
  list-style: disc;
  padding-left: 20px;
  margin: 5px 0px;
}

.footer {
  font-style: normal;
  font-weight: normal;
  line-height: 22px;
  font-size: 12px;
  color: $brand_primary_8;
  text-align: center;
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  padding: 50px 30px;
}
