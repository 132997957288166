/** @format */

@import "../../assets/style/sass/lynk-colors";
@import "../../assets/fonts/manrope/manrope.css";

.resetPwd-page {
  html,
  body {
    height: 100%;
  }

  .pageWrapper {
    width: 448px;
    padding: 40px;
    background: $white;
  }

  .contentWrapper h1 {
    font-style: normal;
    font-weight: normal;
    font-size: 34px;
    line-height: 40px;
    color: $grey_4;
    margin-top: 50px;
    margin-bottom: 30px;
    display: inline-block;
    font-weight: bold;
  }

  .contentWrapper p {
    font-size: 15px;
    line-height: 22px;
    color: $tertiary_7;
    margin-bottom: 20px;
  }

  .infoMsg {
    color: $tertiary_7;
    font-size: 15px;
    line-height: 22px;
  }

  .infoMsg strong {
    font-family: "RobotoMedium";
    font-weight: bold;
  }

  .loginLink {
    position: absolute;
    color: $grey_4;
    font-size: 15px;
    line-height: 22px;
    top: 65px;
    right: 0px;
  }

  .loginLink a {
    color: $neutral_300;
    text-decoration: underline;
  }

  .loginLink a:hover {
    cursor: pointer;
    color: $neutral_800;
  }

  .sep {
    display: block;
    width: 100%;
    border-bottom: 1px solid grey-cool-2;
    position: relative;
    margin: 25px 0px 10px 0px;
  }

  .sep span {
    position: absolute;
    left: 50%;
    z-index: 9;
    transform: translate(-50%);
    top: -12px;
    padding: 0px 10px;
    background: $white;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: tertiary_6;
    font-family: "Roboto", Arial, sans-serif;
  }

  .fieldWrap {
    margin: 12px 0px;
    display: inline-block;
    width: 100%;
  }

  .errorMsg {
    font-size: 13px;
    line-height: 18px;
    color: $alert_2;
    text-align: left;
    margin-top: 5px;
  }

  .btnsWrap {
    text-align: center;
    margin-top: 35px;
  }

  .continue {
    transition: all 200ms ease-in-out;
    background: $neutral_300;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: $white;
    width: 100%;
    display: block;
    padding: 5px;
    margin-bottom: 20px;
    border: none;
    font-family: "Roboto", Arial, sans-serif;
  }

  .continue:hover {
    cursor: pointer;
    background: $neutral_800;
  }

  .continue:disabled {
    background: $tertiary_4;
  }

  .continue:disabled:hover {
    cursor: not-allowed;
  }

  .cancel {
    transition: all 200ms ease-in-out;
    background: none;
    box-shadow: none;
    outline: none;
    border-radius: 5px;
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: alert_2;
    display: inline-block;
    padding: 5px;
    border: none;
    font-family: "Roboto", Arial, sans-serif;
  }

  .cancel:hover {
    cursor: pointer;
    color: $red;
  }

  .errorMessage {
    margin: 20px 0px 15px 0px;
    background: $red;
    color: $white;
    padding: 10px 10px;
    border-radius: 5px;
    font-size: 14px;
  }

  .errorMessage .errorIcon {
    margin-right: 5px;
    font-size: 16px;
  }

  .linkWrap a {
    font-size: 15px;
    line-height: 22px;
    text-decoration-line: underline;
    color: $neutral_300;
    position: relative;
    top: -5px;
  }

  @media (max-width: 768px) {
    .loginLink {
      right: 0px;
      top: 10px;
    }
  }
}
