// @value colors: "styles/branding.css";
// @value branding-mimosa-blue, branding-cobalt-blue, grey_4, grey-cool-2, tertiary_6, alert_2, tertiary_4, tertiary_7, neutral_800  from colors;

/* SIGNUP CSS START */
@import "../../assets/style/sass/lynk-colors";
@import "../../assets/fonts/manrope/manrope.css";

.page-center-align {
  place-items: center;
  width: 100%;
  height: 100vh;
}

.magic-lynk-signup-page {
  width: 100%;
  // height: 100vh;
  display: grid;
  place-items: center;
}

.magic-lynk-signup-form {
  background-color: $white;
  box-shadow: rgba($neutral_500_base, 0.16) 0px 0px 1px,
    rgba($neutral_500_base, 0.16) 0px 2px 4px;
  padding: 4rem;
  width: 48rem;
  display: flex;
  flex-direction: column;

  header {
    font-family: 'manrope';
    font-size: 24px;
    font-weight: 800;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: center;
    margin: unset;
    display: inline-block;
    color: $neutral_800;
    width: 100%;
    padding-bottom: 42px;
  }

  .custom-input-widget:last-of-type {
    margin-top: 1rem;
  }

  @media screen and (max-width: 768px) {
    & {
      width: 100%;
    }
  }
}