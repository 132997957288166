@value colors: '../../styles/branding.css';
@value neutral_800,brand_white from colors;

@font-face {
  font-family: Roboto;
  src: url('../../assets/fonts/Roboto-Regular.ttf') format('truetype');
}

.contentWrap {
  background-color: brand_white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding:40px;
  min-width: 480px;
  box-shadow: 0px 0px 1px rgb(130 136 148 / 16%),
    0px 2px 4px rgb(130 136 148 / 16%);
}

.lynkBrand {
  min-width: 144px;
  max-height: 75px;
  line-height: 48px;
  font-family: Roboto, Arial, sans-serif;
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
}

.lynkBrand .poweredBy {
  color: neutral_800;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
}

.imageHeader{
  max-height: 75px;
}

.lynkBrand img {
  margin: 0 0 40px 10px;
  height: 72px;
  width: 234px;
}

@media (max-width: 768px) {
  .contentWrap{
    width: fit-content;
    min-width: 360px;
  }

}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pnLogo {
  max-width: 225px;
  min-height: 48px;
}
