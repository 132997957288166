@import "../../assets/style/sass/lynk-colors";

.contentColumn {
  padding: 2rem 1.5rem;
  background: $white;
  max-width: 800px;
}

.contentColumnWide {
  padding: 3.125rem 2.5rem;
  background: $white;
  max-width: 1000px;
}

.contentColumnUltraWide {
  padding: 3.125rem 2.5rem;
  background: $white;
  max-width: 1200px;
}

.header {
  font-size: 2.075rem;
  color: #4A4A4A;
  letter-spacing: 0.0575rem;
  text-align: center;
}

.headerTitleSingle {
  margin-bottom: 2rem;
}

.headerTitle {
  margin-bottom: 0.625rem;
}

.headerSubTitle {
  margin-bottom: 1.875rem;
  font-size: 1.475rem;
  color: #4A4A4A;
  letter-spacing: 0;
}

.header img {
  margin-bottom: 1.125rem;
}

@media (min-width: 768px) {
  .contentColumnUltraWide,
  .contentColumnWide,
  .contentColumn {
    margin: 5rem auto 2rem auto;
  }

  .contentColumn {
    padding: 3.125rem 12.5rem;
  }
}
