.wrapper {
  height: 60px;
  align-items: center;
  // border-bottom: 1px solid #dce1e3;
}

.content {
  padding: 0 16px;
  max-width: 800px;
  width: 100%;
  margin: auto;
  display: flex;
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
}

.compliance_headertext {
  font-weight: 800;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: middle;
  margin-left: 9px;
  color: #4a5158;
  font-family: 'Roboto';
}
