.ant-tooltip {
  font-family: Manrope, Roboto, sans-serif;
  width: fit-content;

  .ant-tooltip-inner {
    padding: 12px;
    background-color: #38373B;

    a {
      margin-top: 20px;
      display: inline-block;
      color: #FFF;
      font-weight: normal;
      text-decoration: underline;
    }

    p {
      font-weight: normal;
      line-height: 22px;
      margin: 0;
    }

    .h6.title {
      margin-bottom: 8px;
      line-height: 22px;
    }
  }

  .ant-tooltip-arrow {
    width: 40px;
    height: 24px;
  }

  .ant-tooltip-arrow::before {
    width: 18px;
    background-color: #000;
    height: 18px;
  }
}
