
@import "../../assets/style/sass/lynk-colors";
@import "../../assets/fonts/manrope/manrope.css";

/* SIGNUP CSS START */

@font-face {
  font-family: 'Roboto';
  src: url('../../assets/fonts/Roboto-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'RobotoBold';
  src: url('../../assets/fonts/Roboto-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'RobotoLight';
  src: url('../../assets/fonts/Roboto-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'RobotoThin';
  src: url('../../assets/fonts/Roboto-Thin.ttf') format('truetype');
}

@font-face {
  font-family: 'RobotoMedium';
  src: url('../../assets/fonts/Roboto-Medium.ttf') format('truetype');
}

.form-selfSignUp {
  html,
  body {
    height: 100%;
  }

  .pageWrapper {
    width: 100%;
    height: 100vh;
    display: grid;
    place-items: center;
    place-content: center;
  }

  .signUpForm {
    background-color: $white;
    box-shadow: rgb(141 138 148 / 16%) 0px 0px 1px, rgb(141 138 148 / 16%) 0px 2px 4px;
    padding: 4rem;
    width: 48rem;
    display: flex;
    flex-direction: column;
    width: 100%;
    place-items: center;
  }

  .partialSignUpForm {
    max-width: 400px;
  }

  .completeSignUpForm {
    max-width: 400px;
  }

  h1 {
    font-family: 'manrope';
    font-size: 24px;
    font-weight: 800;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: center;
    width: 100%;
    margin-top: 0px;
    margin-bottom: 35px;
    color: $neutral_800;
  }

  .headingWrap {
    position: relative;
  }

  .loginLink {
    position: absolute;
    color: $neutral_800;
    font-size: 15px;
    line-height: 22px;
    top: 100%;
    right: 1%;
    width: 100%;
    text-align: center;
    font-family: 'manrope';
    margin-top: 14%;
  }

  .loginLink a {
    font-weight: 700;
    color: $neutral_800;
    text-decoration: underline;
  }

  .loginLink a:hover {
    cursor: pointer;
    color: $neutral_800;
  }

  .sep {
    display: block;
    width: 100%;
    border-bottom: 1px solid $grey-cool-2;
    position: relative;
    margin: 30px 0px 25px 0px;
  }

  .sep span {
    position: absolute;
    left: 50%;
    z-index: 9;
    transform: translate(-50%);
    top: -12px;
    padding: 0px 10px;
    background: white;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: tertiary_6;
    font-family: 'Roboto', Arial, sans-serif;
  }

  .fieldWrap {
    margin: 12px 0px;
    display: inline-block;
    width: 100%;
  }

  .fieldWrap:first-child {
    margin-top: 0px;
  }

  .errorMessage {
    margin: 4px 0px 12px 0px;
    // background: $red;
    color: $red;
    border-radius: 5px;
    font-size: 14px;
  }

  .errorMessage .errorIcon {
    color: alert_2;
    font-size: 14px;
    position: absolute;
    left: 0px;
    top: 2px;
  }

  .formBody .errorMessage {
    padding-left: 0px;
  }

  .btnsWrap {
    text-align: center;
    margin-top: 35px;
  }

  .continue {
    transition: all 200ms ease-in-out;
    background: neutral_300;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: white;
    width: 100%;
    display: block;
    padding: 5px;
    margin-bottom: 20px;
    border: none;
    font-family: 'Roboto', Arial, sans-serif;
  }

  .continue:hover {
    cursor: pointer;
    background: $neutral_800;
  }

  .continue:disabled {
    background: tertiary_4;
  }

  .continue:disabled:hover {
    cursor: not-allowed;
  }

  .footerSignupLanding,
  .footerSignupComplete {
    position: absolute;
    bottom: 32px;
    left: 48%;
  }

  .cancel {
    transition: all 200ms ease-in-out;
    background: none;
    box-shadow: none;
    outline: none;
    border-radius: 5px;
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: alert_2;
    display: inline-block;
    padding: 5px;
    border: none;
    font-family: 'Roboto', Arial, sans-serif;
  }

  .cancel:hover {
    cursor: pointer;
    color: branding-red;
  }

  .magicLinkSentMsg {
    margin-top: 10px;
    font-size: 13px;
    line-height: 18px;
    color: $neutral_800;
  }

  .linkedInHelpText {
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 13px;
    line-height: 18px;
    color: tertiary_7;
    opacity: 0.5;
  }

  .helpTextLink {
    text-decoration: underline;
  }

  @media (max-width: 768px) {
    .headingWrap {
      position: unset;
    }
    .footerSignupLanding {
      position: absolute;
      top: 95vh;
    }

    .footerSignupComplete {
      position: absolute;
      left: 45%;
      bottom: -30vh;
      padding-bottom: 20px;
    }
    
    .loginLink {
      margin: 10px 0px 25px 0px;
      display: block;
      width: 100%;
    }

    @media (max-width: 400px) {
      .completeSignUpForm {
        padding-top: 150px;
      }
    }
  }
}
