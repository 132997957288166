@import "../../../assets/style/sass/_lynk-colors.scss";
.lynk-logo {
  position: absolute;
  bottom: 2rem;
  left: 0;
  right: 0;
  margin: auto;
}

.signup-page {
  width: 100%;
  height: 100vh;
  display: grid;
  place-items: center;
}

.signup-team__wrapper {
  overflow: auto;
  height: 100vh;
  background: $neutral_25;
}
.signup-header-title {
  font-family: Manrope;
  font-size: 24px;
  font-weight: 800;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: center;
}

.signup-team__container {
  background-color: $white;
  box-shadow: rgba($neutral_500_base, 0.16) 0px 0px 1px,
    rgba($neutral_500_base, 0.16) 0px 2px 4px;
  padding: 4rem;
  width: 48rem;
  display: flex;
  flex-direction: column;

  header {
    font-family: "manrope";
    font-size: 24px;
    font-weight: 800;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: center;
    margin: unset;
    display: inline-block;
    color: $neutral_800;
    width: 100%;
    padding-bottom: 42px;
  }

  .custom-input-widget:last-of-type {
    margin-top: 1rem;
  }

  @media screen and (max-width: 768px) {
    & {
      width: calc(100% - 4rem);
    }
  }
}
.createAccountButton:disabled {
  cursor: none;
}

.input-form-align {
  margin-top: 16px;
}


.has-error {
  color: #a94442;
  .control-label {
      display: flex;
      border-radius: 3px;
      color: #a94442;
      background-color: #f2dede;
      border-color: #ebccd1;
      font-size: 14px;
      font-weight: normal;
      margin: 1em 0 0;
      padding: 0.7em 1em;
      width: 100%;

      &:before {
          content: '\f071';
          margin-right: 4px;
      }
  }
}


