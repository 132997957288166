/** @format */

@import "../../assets/style/sass/lynk-colors";
@import "../../assets/fonts/manrope/manrope.css";

.forgotPwd-page {
  html,
  body {
    height: 100%;
  }

  .pageWrapper {
    height: 100%;
  }

  .headingWrap h1 {
    font-family: 'manrope' !important;
    font-size: 24px;
    font-weight: 800;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: center;
    margin: unset;
    display: inline-block;
    color: $neutral_800;
    width: 100%;
  }

  .headingWrap p {
    font-family: 'manrope';
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: $neutral_800;
  }

  .infoMsg {
    color: $tertiary_7;
    font-size: 15px;
    line-height: 22px;
  }

  .infoMsg strong {
    font-family: "RobotoMedium";
    font-weight: bold;
  }

  .loginLink {
    position: absolute;
    color: $grey_4;
    font-size: 15px;
    line-height: 22px;
    top: 65px;
    right: 0;
  }

  .loginLink a {
    color: $neutral_300;
    text-decoration: underline;
  }

  .loginLink a:hover {
    cursor: pointer;
    color: $neutral_800;
  }

  .sep {
    display: block;
    width: 100%;
    border-bottom: 1px solid #dbe4e1;
    position: relative;
    margin: 25px 0 10px;
  }

  .sep span {
    position: absolute;
    left: 50%;
    z-index: 9;
    transform: translate(-50%);
    top: -12px;
    padding: 0 10px;
    background: $white;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: $tertiary_6;
    font-family: "Roboto", Arial, sans-serif;
  }

  .fieldWrap {
    margin: 12px 0;
    display: inline-block;
    width: 100%;
  }

  .errorMsg {
    font-size: 13px;
    line-height: 18px;
    color: $alert_2;
    text-align: left;
    margin-top: 5px;
  }

  .btnsWrap {
    text-align: center;
    margin-top: 35px;
  }

  .continue {
    transition: all 200ms ease-in-out;
    background: $neutral_300;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: $white;
    width: 100%;
    display: block;
    padding: 5px;
    margin-bottom: 20px;
    border: none;
    font-family: "Roboto", Arial, sans-serif;
  }

  .continue:hover {
    cursor: pointer;
    background: $neutral_800;
  }

  .continue:disabled {
    background: $tertiary_4;
  }

  .continue:disabled:hover {
    cursor: not-allowed;
  }

  .cancel {
    transition: all 200ms ease-in-out;
    background: none;
    box-shadow: none;
    outline: none;
    border-radius: 5px;
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: $alert_2;
    display: inline-block;
    padding: 5px;
    border: none;
    font-family: "Roboto", Arial, sans-serif;
  }

  .cancel:hover {
    cursor: pointer;
    color: $red;
  }

  .errorMessage {
    margin: 20px 0 15px;
    background: $red_2;
    color: $white;
    padding: 10px;
    border-radius: 5px;
    font-size: 14px;
  }

  .errorMessage .errorIcon {
    margin-right: 5px;
    font-size: 16px;
  }

  /* .formBody {
    padding-bottom: 100px;
    } */

  .linkWrap a {
    font-size: 15px;
    line-height: 22px;
    text-decoration-line: underline;
    color: $neutral_300;
    position: relative;
    top: -5px;
  }

  .backLink {
    position: relative;
    color: $neutral_500_base;
    left: 45%;
    font-family: 'manrope';
    font-size: 18px;
    font-weight: 800;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
  }

  .backLink:hover {
    color: $tertiary_7;
    cursor: pointer;
  }

  .floatWrap {
    float: right;
  }

  @media (max-width: 768px) {
    .floatWrap {
      float: none;
    }

    .backLink {
      display: block;
      float: none;
      text-align: center;
      position: static;
      margin-top: 30px;
    }
  }
}
