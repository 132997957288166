@import "../../assets/style/sass/lynk-colors";
@import "../../assets/fonts/manrope/manrope.css";

.magic-lynk-form {
  background-color: $white;
  box-shadow: rgba($neutral_500_base, 0.16) 0px 0px 1px,
    rgba($neutral_500_base, 0.16) 0px 2px 4px;
  padding: 4rem;
  width: 48rem;
  display: flex;
  flex-direction: column;

  header {
    font-family: 'manrope';
    font-size: 24px;
    font-weight: 800;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: center;
    margin: unset;
    display: inline-block;
    color: $neutral_800;
    width: 100%;
    padding-bottom: 42px;
  }

  .custom-input-widget:last-of-type {
    margin-top: 1rem;
  }

  @media screen and (max-width: 768px) {
    & {
      width: 100%;
    }
  }
  
  .fieldWrap {
    margin: 12px 0px;
    display: inline-block;
    width: 100%;
  }

  .errorMsg {
    font-size: 13px;
    line-height: 18px;
    color: $alert_2;
    text-align: left;
    margin-top: 5px;
  }

  .errorMessage {
    margin: 20px 0px 15px 0px;
    background: #EC644B;
    color: #FFF;
    padding: 10px 10px;
    border-radius: 5px;
    font-size: 14px;
  }

  .errorMessage .errorIcon {
    margin-right: 5px;
    font-size: 16px;
  }
  .linkWrap {
    color: $neutral_800;
    top: 100%;
    text-align: center;
    font-family: 'manrope';
    font-size: 12px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    margin-top: -5px;
    a {
      color: $neutral_800;
      text-decoration: underline;
    }
    a:hover {
      cursor: pointer;
      color: $neutral_800;
    }
  }
}
