@import "../../assets/style/sass/lynk-colors";
@import "../../assets/fonts/manrope/manrope.css";

.copytext {
  font-family: 'manrope';
  font-weight:normal;
  font-size: 0.9rem;
  color: $brand_primary_8;
  text-align: center;
}

@media (min-width: 768px) {
  .copytext {
    font-size: 1.5rem;
    letter-spacing: 0.58px;
  }
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: 992px) {
  
}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}
