$grey_1: #f4f6f8;
$grey_4: #98a2a5;
$grey_5: #99a6a5;
$grey_6: #8d8a94;
$grey-cool-2: #dbe4e1;
$grey_bg_1: #f9f9fa;
$alert_2: #e26756;
$note_text: #0238f5;
$note_bg: #f2f5fe;
$brand_primary_1: #f6f2ff;
$brand_primary_2: #9666ff;
$brand_primary_3: #5000ff;
$brand_primary_4: #300099;
$brand_primary_5: #9666ff;
$brand_primary_6: #b999ff;
$brand_primary_7: #4000cc;
$brand_primary_8: #1c1c1e;
$brand_primary_9: #47ffc2;
$brand_primary_10: #9928c5;

$tertiary_7: #4a5158;
$tertiary_6: #71797d;
$tertiary_4: #c5ced0;
$tertiary_3: #dce1e3;
$tertiary_2: #ebeded;
$neutral_25: #f9f9fa;
$neutral_100: #e8e8ea;
$neutral_200: #d1d0d4;
$neutral_300: #bbb9bf;
$neutral_400: #a4a1a9;
$neutral_500_base: #8d8a94;
$neutral_600: #716e76;
$neutral_700: #555359;
$neutral_800: #38373B;
$neutral_900: #1c1c1e;
$primary2_600: #39cc9b;
$red: #cc2f31;
$red_2: #992325;
$yellow: #f6c448;
$white: #fff;
$black: #000;

:export {
    grey_1: $grey_1;
    grey_4: $grey_4;
    grey_5: $grey_5;
    grey_6: $grey_6;
    grey-cool-2: $grey-cool-2;
    grey_bg_1: $grey_bg_1;
    alert_2: $alert_2;
    note_text: $note_text;
    note_bg: $note_bg;
    brand_primary_1: $brand_primary_1;
    brand_primary_2: $brand_primary_2;
    brand_primary_3: $brand_primary_3;
    brand_primary_4: $brand_primary_4;
    brand_primary_5: $brand_primary_5;
    brand_primary_7: $brand_primary_7;
    brand_primary_8: $brand_primary_8;
    brand_primary_6: $brand_primary_6;
    brand_primary_9: $brand_primary_9;

    tertiary_7: $tertiary_7;
    tertiary_6: $tertiary_6;
    tertiary_4: $tertiary_4;
    tertiary_3: $tertiary_3;
    tertiary_2: $tertiary_2;
    neutral_25: $neutral_25;
    neutral_100: $neutral_100;
    neutral_200: $neutral_200;
    neutral_300: $neutral_300;
    neutral_400: $neutral_400;
    neutral_500_base: $neutral_500_base;
    neutral_600: $neutral_600;
    neutral_700: $neutral_700;
    neutral_900: $neutral_900;
    red: $red;
    red_2: $red_2;
    yellow: $yellow;
    white: $white;
}
$brand_other_1: #472BF5;
$background_other1: #669DC0;
$background_other2: #F0F0F5;
