@import "../../assets/style/sass/lynk-colors";
@import "../../assets/fonts/manrope/manrope.css";

.btn {
  background: $neutral_800;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 12px 20px;
  color: $white;
  margin-left: auto;
  box-shadow: none;
  outline: none;
  border: none;
  letter-spacing: 1px;
  transition: all 200ms ease-in-out;
  width: 100%;
  font-family: 'manrope';
  font-size: 18px;
  font-weight: 800;
  letter-spacing: 0em;
}

.btn.wide {
  padding: 12px 80px;
}

.btn:hover {
  background: $neutral_900;
  cursor: pointer;
}

.btn:disabled {
  background: $neutral_200;
}

.btn:disabled:hover {
  cursor: not-allowed;
}

.btnWrap {
  text-align: right;
  margin-top: 20px;
}

@media (max-width: 768px) {
  .btn {
    width: 100%;
  }
  .btn.wide {
    padding: 12px 40px;
  }
}
