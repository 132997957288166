/* @value colors: "styles/branding.css";
@value branding-mimosa-blue, branding-cobalt-blue, neutral_800, neutral_100, brand_white from colors; */
@import "../../assets/style/sass/lynk-colors";
@import "../../assets/fonts/manrope/manrope.css";
.fieldContainer label {
  display: block;
  margin-bottom: 6px;
  font-size: 12px;
  line-height: 18px;
  color: $neutral_800;
  font-weight: 800;
  font-family: "manrope";
  width: 100%;
}

.fieldContainer label .required {
  font-family: "manrope";
  color: #e26756;
  position: relative;
  right: -3px;
  top: -2px;
  font-size: 14px;
}

.fieldContainer input {
  background: $white;
  border: 1px solid $neutral_100;
  box-sizing: border-box;
  border-radius: 5px;
  font-size: 15px;
  line-height: 22px;
  color: #4a5158;
  width: 100%;
  padding: 8px 14px;
  box-shadow: none;
}

.fieldContainer input:focus {
  border: 1px solid $neutral_300;
}

.fieldContainer input.error {
  border: 1px solid #e26756;
}

.toggleBtn {
  position: absolute;
  height: 24px;
  width: 24px;
  padding: 0px;
  right: 6px;
  top: 8px;
  border: none;
  box-shadow: none;
  background: none;
  font-size: 13px;
  line-height: 18px;
  color: $neutral_800;
  z-index: 9;
}

.toggleBtn:hover {
  cursor: pointer;
  color: $neutral_300;
}

.toggleBtnIcon {
  width: 100%;
  height: 100%;
}

.inputWrap {
  position: relative;
}

.helperText {
  font-size: 13px;
  line-height: 18px;
  margin-top: -5px;
  margin-bottom: 10px;
  color: #71797d;
}
.helperText a {
  color: #71797d;
  text-decoration: underline;
}

.fieldLabelContainer {
  display: flex;
}
.fieldLabelContainer img {
  position: relative;
  right: 3px;
  float: right;
  width: 24px;
  padding-right: 5px;
}
@media (max-width: 768px) {
  .toggleBtnIcon {
    width: 75%;
    height: 75%;
  }
}
