.page-center-align {
  place-items: center;
  width: 100%;
  height: 100vh;
}
.signup-magic-lynk-page {
  display: grid;
  place-items: center;
}

.signup-magic-lynk-page {
  width: 100%;
  height: 100vh;
  display: grid;
  place-items: center;
}
