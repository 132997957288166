@import "../../assets/style/sass/lynk-colors";

.basicTncLinks a {
  color: $neutral_800;
  text-decoration: underline;
}

.basicTncLinks a:hover {
  color: $neutral_900
}
