.page-center-align {
  place-items: center;
  width: 100%;
  height: 100vh;
}
.magic-lynk-page {
  display: grid;
  place-items: center;
}

.magic-lynk-login-page {
  width: 100%;
  height: 100vh;
  display: grid;
  place-items: center;
}

.lynk-logo {
  position: absolute;
  bottom: 2rem;
  left: 0;
  right: 0;
  margin: auto;
}
