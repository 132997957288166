// @value colors: "styles/branding.css";
// @value branding-mimosa-blue,tertiary_7,alert_2,grey_1,tertiary_4,grey_4,neutral_800,brand_white,neutral_100 from colors;

@import "../../assets/style/sass/lynk-colors";
@import "../../assets/fonts/manrope/manrope.css";

.fieldContainer label {
  display: block;
  margin-bottom: 6px;
  font-size: 12px;
  line-height: 18px;
  color: neutral_800;
  font-weight: 800;
  font-family: 'manrope';
}

.fieldContainer label .required {
  font-family: 'manrope';
  color: $alert_2;
  position: relative;
  right: -3px;
  top: -2px;
  font-size: 14px;
}

.fieldContainer input[type='text'] {
  background: $white;
  border: 1px solid $neutral_100;
  box-sizing: border-box;
  border-radius: 5px;
  font-size: 15px;
  line-height: 22px;
  color: tertiary_7;
  width: 100%;
  padding: 8px 14px;
  box-shadow: none;
}

.fieldContainer input[type='text']:disabled {
  background-image: url('../../images/signup/lock-verified.svg');
  background-size: 16px 18px;
  background-position: 98% center;
  background-repeat: no-repeat;
  color: $grey_4;
}

.fieldContainer input[type='text']:disabled:hover {
  cursor: not-allowed;
}

.fieldContainer input[type='text']:focus {
  border: 1px solid $background_other2;
}

.fieldContainer input[type='text'].error {
  border: 1px solid $alert_2;
}
@media (max-width: 768px) {
  .fieldContainer input[type='text'] {
    font-size: 14px;
  }
}
