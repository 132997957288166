.footer {
  padding-top: 15px;
  width: 100%;
  padding: 0px;
}

.bordered {
  border-top: 1px solid #dbe4e1;
  padding: 15px 0px 0px 0px;
}

.footer,
.footer a {
  font-size: 12px;
  line-height: 22px;
  text-align: center;
  color: #98a2a5;
}

.footer img {
  position: absolute;
  bottom: 32px;
  left: 48%;
}

.footer a:hover {
  color: #4a5158;
}

.footer .lhs {
  width: 30%;
  float: left;
}

.footer .rhs {
  width: 70%;
  float: left;
  text-align: right;
}

@media (max-width: 768px) {
  .bordered {
    padding: 20px 15px;
    margin: 0px -30px;
  }

  .footer {
    padding: 30px 0px 0px 0px;
  }

  .footerWrapper{
    width: 100%;
    text-align: center;
  }

  .footerNeutral{
    bottom: 16px;
    text-align: center;
    margin-left: 44%;
  }

  .footer img {
    left: 46%;
   
  }

  .footer .lhs {
    width: 100%;
    text-align: center;
    margin-bottom: 15px;
    float: none;
  }

  .footer .rhs {
    width: 100%;
    text-align: center;
    float: none;
  }
}
