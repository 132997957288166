/* Modal Styles */

.modalOverlay {
  display: block;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  opacity: 1;
  z-index: 95;
  background: rgba(0, 0, 0, 0.65);
  overflow-y: scroll;
  transition: all 250ms ease-in;
}

.modalOverlay.hide {
  opacity: 0;
  z-index: -1;
}

.modal {
  min-height: 300px;
  width: 460px;
  background: #F4F6F8;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  position: absolute;
  left: 50%;
  top: 15%;
  z-index: 96;
  transform: translateX(-50%);
  padding: 0px 20px 20px 20px;
  text-align: left;
  margin-bottom: 100px;
  font-family: 'RobotoLight', Arial, sans-serif;
  font-weight: normal;
  font-size: 15px;
  line-height: 22px;
  color: #4A5158;
}

.modalHeader {
  background: #98A2A5;
  font-weight: normal;
  font-size: 20px;
  line-height: 28px;
  color: #FFFFFF;
  margin: 0px -20px 20px -20px;
  padding: 15px 25px;
  border-radius: 5px 5px 0px 0px;
  position: relative;
}

.modalClose {
  position: absolute;
  top: 8px;
  right: 20px;
  z-index: 90;
  color: #FFF;
  border: none;
  outline: none;
  box-shadow: none;
  padding: 5px;
}

.modalClose:hover {
  cursor: pointer;
}

.modal p {
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .modal {
    width: 100%;
    position: fixed;
    left: 0px;
    top: 0px;
    border-radius: 0px;
    transform: none;
    height: 100%;
  }

  .modal .modalHeader {
    border-radius: 0px;
  }
}
