@import "../../assets/style/sass/lynk-colors";
@import "../../assets/fonts/manrope/manrope.css";

/* SIGNUP CSS START */

@font-face {
  font-family: 'Roboto';
  src: url('../../assets/fonts/Roboto-Regular.ttf')  format('truetype');
}

@font-face {
  font-family: 'RobotoBold';
  src: url('../../assets/fonts/Roboto-Bold.ttf')  format('truetype')
}

@font-face {
  font-family: 'RobotoLight';
  src: url('../../assets/fonts/Roboto-Light.ttf')  format('truetype')
}

@font-face {
  font-family: 'RobotoThin';
  src: url('../../assets/fonts/Roboto-Thin.ttf')  format('truetype')
}

@font-face {
  font-family: 'RobotoMedium';
  src: url('../../assets/fonts/Roboto-Medium.ttf')  format('truetype')
}

html, body {
  height: 100%;
}

h1 {
  font-family: Manrope;
  font-size: 24px;
  font-weight: 800;
  line-height: 36px;
  letter-spacing: 0em;
  color:$neutral_800;
  margin-top: 50px;
  margin-bottom: 35px;
  display: inline-block;
  width: 100%;
  text-align: center;
}

.signup-magic-lynk-form {
  background-color: $white;
  box-shadow: rgba($neutral_500_base, 0.16) 0px 0px 1px,
    rgba($neutral_500_base, 0.16) 0px 2px 4px;
  padding: 4rem;
  max-width: 480px;
  display: flex;
  flex-direction: column;

  header {
    font-family: 'manrope';
    font-size: 24px;
    font-weight: 800;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: center;
    margin: unset;
    display: inline-block;
    color: $neutral_800;
    width: 100%;
    padding-bottom: 32px;
  }

  .custom-input-widget:last-of-type {
    margin-top: 1rem;
  }
  .footerImage {
    position: absolute;
    bottom: 32px;
    left: 48%;
  }

  @media screen and (max-width: 768px) {
    & {
      width: 100%;
      min-width: 360px;
    }
    .footerImage {
      position: absolute;
      left: 45%;
      bottom: -20vh;
      padding-bottom: 20px;
    }
  }
  .errorMsg {
    font-size: 13px;
    line-height: 18px;
    color: $alert_2;
    text-align: left;
    margin-top: 5px;
  }
  
  .errorMessage {
    margin: 20px 0px 15px 0px;
    background: #EC644B;
    color: $white;
    padding: 10px 10px;
    border-radius: 5px;
    font-size: 14px;
  }
  
  .errorMessage .errorIcon {
    margin-right: 5px;
    font-size: 16px;
  }
}

.custom-input-widget {
  display: flex;
  flex-direction: column;

  label {
    font-family: 'manrope', sans-serif;
    font-size: 1.2rem;
    font-weight: 800;
    line-height: 1.8rem;
    letter-spacing: 0;
    color: $neutral_800;
    margin-bottom: 0.5rem;
  }

  input {
    background: $white none repeat scroll 0% 0%;
    border-radius: 2px;
    border: 1px solid $neutral_100;
    color: $brand_primary_8;
    font-size: 1.4rem;
    display: block;
    width: 100%;
    height: 3.4rem;
    padding: 0 1.2rem;
    outline: none;
    text-decoration: none;
    background-size: 16px 18px;
    background-position: 98% center;
    background-repeat: no-repeat;
    color: $grey_4;
  }

  input[type='text']:disabled {
    background-image: url('../../images/lock-verified.svg');
    background-size: 16px 18px;
    background-position: 98% center;
    background-repeat: no-repeat;
    color: $grey_4;
  }
  
  input[type='text']:disabled:hover {
    cursor: not-allowed;
  }
}

.custom-button-widget {
  width: 100%;
  margin-top: 3.2rem;
  font-size: 1.6rem;
  border: 0.2rem solid $neutral_800;
  cursor: pointer;
  display: inline-block;
  padding: 0px 2rem;
  min-width: 21rem;
  height: 4rem;
  text-align: center;
  border-radius: 0.4rem;
  color: $white;
  background-color: $neutral_800;
  transition: all 0.25s cubic-bezier(0.17, 0.67, 0.52, 0.97) 0s;
  box-shadow: rgba($neutral_800, 0.11) 0px 4px 6px,
    rgba($black, 0.08) 0px 1px 3px;

  &:hover {
    background-color: $neutral_900;
    transform: translateY(-1px);
    box-shadow: rgba($neutral_800, 0.1) 0px 7px 14px,
      rgba($black, 0.08) 0px 3px 6px;
  }
}
.fieldWrap {
  margin: 12px 0px;
  display: inline-block;
  width: 100%;
}

p.supportText {
  font-size: 14px;
  line-height: 18px;
  color: $tertiary_6;
  text-align: left;
  margin-top: 0px;
  margin-bottom: 5px;
}

p.supportText a {
  color: $tertiary_6;
  text-decoration: underline;
}

p.supportText a:hover {
  cursor: pointer;
}

.linkedInWrapper {
  margin-bottom: 10px;
}

.linkedInWrapper a {
  font-size: 14px;
  line-height: 21px;
  color: #388CBE;
  text-decoration: underline;
  font-family: 'Roboto';
}

.linkedInWrapper a:hover {
  color: #226993;
  cursor: pointer;
}

.linkedInWrapper a img {
  position: relative;
  top: 3px;
  display: inline-block;
  margin-left: 5px;
}

.linkedInWrapper a:hover {
  cursor: pointer;
}

.linkedInHelpText {
  font-size: 13px;
  line-height: 18px;
  color: $tertiary_7;
  opacity: 0.5;
}

.helpTextLink {
  color: $tertiary_6;
  text-decoration: underline;
}

.btnsWrap {
  text-align: center;
  margin-top: 35px;
}

.continue {
  transition: all 200ms ease-in-out;
  background: branding-mimosa-blue;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: brand_white;
  width: 100%;
  display: block;
  padding: 5px;
  margin-bottom: 20px;
  border: none;
  font-family: 'Roboto', Arial, sans-serif;
}

.continue:hover {
  cursor: pointer;
  background: branding-cobalt-blue;
}

.continue:disabled {
  background: tertiary_4;
}

.continue:disabled:hover {
  cursor: not-allowed;
}

.cancel {
  transition: all 200ms ease-in-out;
  background: none;
  box-shadow: none;
  outline: none;
  border-radius: 5px;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: alert_2;
  display: inline-block;
  padding: 5px;
  border: none;
  font-family: 'Roboto', Arial, sans-serif;
}

.cancel:hover {
  cursor: pointer;
  color: red;
}
.sep {
  display: block;
  width: 100%;
  border-bottom: 1px solid $grey-cool-2;
  position: relative;
  margin: 30px 0px 25px 0px;
}

.sep span {
  position: absolute;
  left: 50%;
  z-index: 9;
  transform: translate(-50%);
  top: -12px;
  padding: 0px 10px;
  background: white;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: tertiary_6;
  font-family: 'Roboto', Arial, sans-serif;
}