.mfa-setup ol li {
  margin: 10px 0;
}
.mfa-setup ol {
  padding-left: 20px;
  margin-top: 10px;
}

.mfa-setup input.form-control {
  background: #f4f6f8;
  border: 0;
  box-shadow: none;
  border-radius: 2px;
}

.signup__email-container {
  background-color: #ffffff;
  box-shadow: rgba(#8d8a94, 0.16) 0px 0px 1px, rgba(#8d8a94, 0.16) 0px 2px 4px;
  padding: 4rem;
  max-width: 480px;
  display: flex;
  flex-direction: column;
  font-family: 'manrope';
    font-size: 15px;
    line-height: 22px;

  /* label {
    font-family: "manrope", sans-serif;
    font-size: 1.2rem;
    font-weight: 800;
    line-height: 1.8rem;
    letter-spacing: 0;
    color: #38373b;
    margin-bottom: 0.5rem;
  } */
}

.signup__email-container label.form-control {
  font-family: "manrope", sans-serif;
    font-size: 1.2rem;
    font-weight: 800;
    line-height: 1.8rem;
    letter-spacing: 0;
    color: #38373b;
    margin-bottom: 0.5rem;
    padding-top: 5px;
}
.signup__email-container input.form-control {
  background: #ffffff none repeat scroll 0% 0%;
  border-radius: 2px;
  border: 1px solid #e8e8ea;
  color: #1c1c1e;
  font-size: 1.4rem;
  display: block;
  width: 100%;
  height: 3.4rem;
  padding: 0 1.2rem;
  outline: none;
  text-decoration: none;
  background-size: 16px 18px;
  background-position: 98% center;
  background-repeat: no-repeat;
  color: #98a2a5;
  margin-top: 24px;
}

.signup__email-container .mfa-setup {
  max-width: 500px;
}

.mfa-setup input.form-control {
  margin-top: 8px;
}