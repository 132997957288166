.linkedInBtn {
  width: 100%;
  background: #388cbe;
  transition: all 200ms ease-in-out;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  border: none;
  border-radius: 5px;
  color: #fff;
  text-align: left;
  padding: 9px 10px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  font-family: 'manrope';
  font-size: 14px;
  font-weight: 800;
  line-height: 22px;
  letter-spacing: 0em;
  margin: 0px 0px 20px 0px;
}

.linkedInBtn:hover {
  background: #226993;
  cursor: pointer;
}

.linkedInBtnIcon {
  height: 18.5px;
  width: 18.5px;
  background: url('../../../images/signup/linkedin-small.png');
  background-repeat: no-repeat;
  background-size: 18.5px;
  background-position: right;
  top: 10px;
  margin-left: 12px;
}

.linkedInBtn[disabled='disabled'],
.linkedInBtn:disabled {
  cursor: not-allowed;
  background: #c5ced0;
}
