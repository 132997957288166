@import "../../assets/style/sass/lynk-colors";
@import "../../assets/fonts/manrope/manrope.css";

.login-form {
  background-color: $white;
  box-shadow: rgba($neutral_500_base, 0.16) 0px 0px 1px,
    rgba($neutral_500_base, 0.16) 0px 2px 4px;
  padding: 4rem;
  width: 48rem;
  display: flex;
  flex-direction: column;

  header {
    font-family: 'manrope';
    font-size: 24px;
    font-weight: 800;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: center;
    margin: unset;
    display: inline-block;
    color: $neutral_800;
    width: 100%;
    padding-bottom: 42px;
  }

  .custom-input-widget:last-of-type {
    margin-top: 1rem;
  }

  @media screen and (max-width: 768px) {
    & {
      width: calc(100% - 4rem);
      min-width:360px;
    }
  }

  .errorMessage {
    margin: 0px 0px 20px 0px;
    background: #fcf5f5;
    color: $red;
    padding: 10px 10px;
    border-radius: 5px;
    font-size: 14px;
  }

  .errorMessage .errorIcon {
    margin-right: 5px;
    font-size: 16px;
  }
  .linkWrap {
    color: $neutral_800;
    top: 100%;
    text-align: center;
    font-family: 'manrope';
    font-size: 12px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    margin-top: -5px;
    a {
      color: $neutral_800;
      text-decoration: underline;
    }
    a:hover {
      cursor: pointer;
      color: $neutral_800;
    }
  }
}

.custom-input-widget {
  display: flex;
  flex-direction: column;

  label {
    font-family: 'manrope', sans-serif;
    font-size: 1.2rem;
    font-weight: 800;
    line-height: 1.8rem;
    letter-spacing: 0;
    color: $neutral_800;
    margin-bottom: 0.5rem;
  }

  input {
    background: $white none repeat scroll 0% 0%;
    border-radius: 2px;
    border: 1px solid $neutral_100;
    color: #7b8994;
    font-size: 1.4rem;
    display: block;
    width: 100%;
    height: 3.4rem;
    padding: 0 1.2rem;
    outline: none;
    text-decoration: none;
  }
}

.custom-button-widget {
  width: 100%;
  margin-top: 3.2rem;
  font-size: 1.6rem;
  border: 0.2rem solid $neutral_800;
  cursor: pointer;
  display: inline-block;
  padding: 0px 2rem;
  min-width: 21rem;
  height: 4rem;
  text-align: center;
  border-radius: 0.4rem;
  color: $white;
  background-color: $neutral_800;
  transition: all 0.25s cubic-bezier(0.17, 0.67, 0.52, 0.97) 0s;
  box-shadow: rgba($neutral_800, 0.11) 0px 4px 6px,
    rgba($black, 0.08) 0px 1px 3px;

  &:hover {
    background-color: $neutral_900;
    transform: translateY(-1px);
    box-shadow: rgba($neutral_800, 0.1) 0px 7px 14px,
      rgba($black, 0.08) 0px 3px 6px;
  }
}

.wrapper {
  background: $grey_1;
}

.signupLink {
  color: #38373B;
  top: 100%;
  text-align: center;
  font-family: Manrope;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  margin-top: 16px;
  a {
    color: #38373B;
    text-decoration: underline;
    font-weight: 800;
  }
  a:hover {
    cursor: pointer;
    color: #38373B;
  }
}

.fieldWrap {
  margin: 12px 0px;
  width: 100%;
}

.errorMsg {
  font-size: 13px;
  line-height: 18px;
  color: $alert_2;
  text-align: left;
  margin-top: 5px;
}

