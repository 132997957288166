/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on July 21, 2020 */
@font-face {
    font-family: 'manrope';
    src: url('manrope-bold-webfont.woff2') format('woff2'),
         url('manrope-bold-webfont.woff') format('woff');
    font-weight: 700;
    font-style: 700;
}

@font-face {
    font-family: 'manrope';
    src: url('manrope-extrabold-webfont.woff2') format('woff2'),
         url('manrope-extrabold-webfont.woff') format('woff');
    font-weight: 800;
    font-style: 800;
}

@font-face {
    font-family: 'manrope';
    src: url('manrope-extralight-webfont.woff2') format('woff2'),
         url('manrope-extralight-webfont.woff') format('woff');
    font-weight: 200;
    font-style: 200;
}

@font-face {
    font-family: 'manrope';
    src: url('manrope-light-webfont.woff2') format('woff2'),
         url('manrope-light-webfont.woff') format('woff');
    font-weight: 300;
    font-style: 300;
}

@font-face {
    font-family: 'manrope';
    src: url('manrope-medium-webfont.woff2') format('woff2'),
         url('manrope-medium-webfont.woff') format('woff');
    font-weight: 500;
    font-style: 500;
}

@font-face {
    font-family: 'manrope';
    src: url('manrope-regular-webfont.woff2') format('woff2'),
         url('manrope-regular-webfont.woff') format('woff');
    font-weight: 400;
    font-style: 400;
}

@font-face {
    font-family: 'manrope';
    src: url('manrope-semibold-webfont.woff2') format('woff2'),
         url('manrope-semibold-webfont.woff') format('woff');
    font-weight: 600;
    font-style: 600;
}
