@import "../../assets/style/sass/lynk-colors";

.redirect-component {
  display: grid;
  place-items: center;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  color: $neutral_800;
  font-weight: 800;
  font-size: 1.5rem;
  font-family: 'manrope';
}
